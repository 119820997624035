div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 34px;
  margin: 0px;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #eee;
  padding: 3px 5px;
  outline-width:0px;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 1rem;
  display: inline-block;
  padding: 5px 10px;
  margin: 3px 5px;
  cursor: move;
  border-radius: 2px;
  &>button{
    margin-left: 15px;
    color: red;
    border: none;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
  }
  &:hover{
    &>button{
      //display: block;
    }
  }
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 15px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
